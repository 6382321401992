<template>

    <div class="componente_domanda">

        <div v-if="domanda.type=='select'">

            <q-select
                v-show="visible"
                emit-value
                map-options
                outlined

                virtual-scroll-slice-size="0"
                virtual-scroll-item-size="0"

                :disable="domanda.is_disabled"
                :filled="domanda.is_disabled"
                :name="domanda.name"
                v-model="domanda.value"
                :options="getRisposte"
                :label="domanda.label"
                :error="isNotValid"
                :error-message="errorMessage"
                @blur="onBlur()"
                @input="$emit('qq-domanda-change')"
            >

            </q-select>
        </div>

        <div v-if="domanda.type=='input_select'">

            <q-select
                ref="input_select"
                v-show="visible"
                emit-value
                map-options
                outlined
                use-input
                input-debounce="0"
                :disable="domanda.is_disabled"
                :filled="domanda.is_disabled"
                v-model="domanda.value"
                :name="domanda.name"
                :options="getRisposte"
                :label="domanda.label"
                :error="isNotValid"
                :error-message="errorMessage"
                @blur="onBlur()"
                @input="$emit('qq-domanda-change')"
                @filter="onInputSelectFilter"
            >
                <template v-slot:no-option>
                    <q-item>
                        <q-item-section class="text-grey">
                        Nessun risultato
                        </q-item-section>
                    </q-item>
                </template>

            </q-select>
        </div>

        <div v-if="domanda.type=='number'">
            <q-input
                type="number"
                v-show="visible"
                v-model="domanda.value"
                outlined
                :name="domanda.name"
                :label="domanda.label"
                :error="isNotValid"
                :error-message="errorMessage"
                :disable="domanda.is_disabled"
                :filled="domanda.is_disabled"
                step="1"
                @blur="onBlur()"
                @input="$emit('qq-domanda-change')"
            >
            <template v-slot:prepend>
                {{ domanda.label_prepend }}
            </template>

            <template v-slot:append>
                {{ domanda.label_append }}
            </template>

            </q-input>
        </div>

        <div v-if="domanda.type == 'group_checkbox'" align="left">
            <fieldset v-show="visible" class="q-mb-md">
                <legend>{{domanda.label}}</legend>
                    <q-option-group
                        :options="getRisposte"
                        :label="domanda.label"
                        type="checkbox"
                        :name="domanda.name"
                        v-model="domanda.value"
                        :disable="domanda.is_disabled"
                        @input="$emit('qq-domanda-change')"
                    />
            </fieldset>
        </div>

        <div v-if="domanda.type=='radio'" align="left">
            <fieldset v-show="visible" class="q-mb-md">
                <legend>{{domanda.label}}</legend>
                <div align="left">
                    <q-option-group
                        :options="getRisposte"
                        :label="domanda.label"
                        :name="domanda.name"
                        type="radio"
                        v-model="domanda.value"
                        :disable="domanda.is_disabled"
                        @input="$emit('qq-domanda-change')"
                        :data-cy="domanda.name"
                    />
                        <!-- <template v-slot:label="opt">
                            <div class="row items-center" :data-cy="domanda.name + '_' + opt.value">
                                <span class="text-teal">{{ opt.label }}</span>
                            </div>
                        </template>
                    </q-option-group> -->
                </div>
            </fieldset>
        </div>

        <div v-if="domanda.type=='big-radio'" align="left">
            <fieldset v-show="visible" class="q-mb-md">

                <div class="row">
                    <div class="cl-12 col-md-8" v-html="domanda.label">

                    </div>
                    <div class="cl-12 col-md-4" align="center">
                        <q-option-group
                            :options="getRisposte"
                            :label="domanda.label"
                            :name="domanda.name"
                            type="radio"
                            v-model="domanda.value"
                            :disable="domanda.is_disabled"
                            @input="$emit('qq-domanda-change')"
                            :data-cy="domanda.name" />
                    </div>
                </div>
            </fieldset>
        </div>

        <div v-if="domanda.type == 'date'" align="center">
            <q-input outlined
                v-show="visible"
                v-model="domanda.value"
                mask="##/##/####"
                :label="domanda.label"
                :name="domanda.name"
                :error="isNotValid"
                :error-message="errorMessage"
                :disable="domanda.is_disabled"
                :filled="domanda.is_disabled"
                @blur="onBlur()"
                @input="$emit('qq-domanda-change')">

                <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="domanda.value"
                            :name="domanda.name"
                            mask="DD/MM/YYYY"
                            v-show="visible">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Close" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                    </q-icon>
                </template>
            </q-input>
        </div>

        <div v-if="domanda.type == 'text'" align="center">
            <q-input outlined
                class="textbox"
                v-model="domanda.value"
                :label="domanda.label"
                :name="domanda.name"
                v-show="visible"
                :error="isNotValid"
                :error-message="errorMessage"
                :disable="domanda.is_disabled"
                :filled="domanda.is_disabled"
                @blur="onBlur()"
                @input="$emit('qq-domanda-change')"
                :placeholder="domanda.placeholder" />
        </div>

        <div v-if="domanda.type == 'textarea'" align="center">
                <q-input
                    :ref="domanda.type"
                    outlined
                    :label="domanda.label"
                    v-show="visible"
                    :name="domanda.name"
                    type="textarea"
                    v-model="domanda.value"
                    :error="isNotValid"
                    :error-message="errorMessage"
                    :disable="domanda.is_disabled"
                    :filled="domanda.is_disabled"
                    @blur="onBlur()"
                    @input="$emit('qq-domanda-change')"
                    :placeholder="domanda.placeholder"
                />
        </div>

        <div v-if="domanda.type == 'email'" align="center">
            <q-input outlined
                v-model="domanda.value"
                type="email"
                prefix=""
                :label="domanda.label"
                 v-show="visible"
                :error="isNotValid"
                :error-message="errorMessage"
                :name="domanda.name"
                :disable="domanda.is_disabled"
                :filled="domanda.is_disabled"
                @blur="onBlur()"
                @input="$emit('qq-domanda-change')"
                :placeholder="domanda.placeholder"
                suffix="">
                <template v-slot:prepend>
                    <q-icon name="mail" />
                </template>
            </q-input>
        </div>

        <div v-if="domanda.type == 'title'" align="center" v-show="visible">
            <h4 class="q-my-sm">{{domanda.label}}</h4>
        </div>

        <div v-if="domanda.type == 'separator'" align="center" v-show="visible">
            <hr class="q-my-sm" />
        </div>

        <div v-if="domanda.type == 'button'" align="center" v-show="visible">

            <q-btn
                color="secondary"
                text-color="white"
                :label="domanda.label"
                :disable="domanda.is_disabled"
                class="q-mb-sm"
                style="width: 200px; padding: 16px;"
                @click.native="btnClickEvent(domanda.label)"
            />

        </div>

    </div>
</template>

<script>
    import commonLib from "@/libs/commonLib";
    import validation from "@/libs/validation";
    //import QQButton from "@/components/QQButton";

    export default {
        name: "QQDomande",
        data() {
            return {
                errorMessage: "",
                selectedField: false,
                valore: 0
            }
        },
        //components: {
        //    QQButton
        //},
        props: {
            questionario_completo: {
                type: Array,
                required: true
            },
            domanda: {
                type: Object,
                required: true
            },
            analisi_necessita: {
                type: Array,
                required: false,
                default: function() {
                    return [];
                }
            },
            visible: {
                type: Boolean,
                required: false,
                default: true
            },
            min: {
                type: Number,
                required: false,
                default: 0
            },
            max: {
                type: Number,
                required: false,
                default: 9999999
            }
        },
        computed: {
            getRisposte() {
                let risposte = this.domanda.options;

                //console.log("risposte:",risposte);

                // verifica quali sono le risposte da visualizzare
                let risposteValide = risposte.filter(risposta => {

                    if (risposta.is_visible_if === "") return true;

                    //console.log("risposta.is_visible_if: ",risposta.is_visible_if);

                    // Nel caso ci siano opzioni multiple, le cicla tutte
                    var elenco_condizioni = risposta.is_visible_if.split(";");
                    var esiste = true;

                    elenco_condizioni.forEach(condizione => {
                        console.log("singola condizione:",risposta.label,condizione);

                        let NomeCampo = condizione.split(":")[0];
                        let valoriAccettati = condizione.split(":")[1];

                        // cerca il campo tra le domande
                        let Domanda = this.questionario_completo.filter(singolaDomanda => {
                            return singolaDomanda.name === NomeCampo;
                        });

                        if (Domanda.length === 0) {
                            Domanda = this.analisi_necessita.filter(singolaDomanda => {
                                return singolaDomanda.name === NomeCampo;
                            });
                        }

                        let value = "";

                        if (Domanda.length > 0) {
                            value = Domanda[0].value;
                            //esiste = esiste && false;
                            var elenco = valoriAccettati.split(",");

                            console.log("Valori accettati:",elenco,value);

                            if (elenco.includes(value)) esiste = esiste && true;
                                                  else  esiste = false;

                            /*var len = elenco.length;
                            for (var i=0;i<len;i++) {
                                if (elenco[i] === value) esiste = esiste && true;
                                                    //else esiste = false;
                            }*/
                        }
                    });

                /*    let NomeCampo = risposta.is_visible_if.split(":")[0];
                    let valoriAccettati = risposta.is_visible_if.split(":")[1];

                    // cerca il campo tra le domande
                    let Domanda = this.questionario_completo.filter(singolaDomanda => {
                        return singolaDomanda.name === NomeCampo;
                    });

                    if (Domanda.length === 0) {

                        Domanda = this.analisi_necessita.filter(singolaDomanda => {
                            return singolaDomanda.name === NomeCampo;
                        });

                    }

                    let value = "";
                    var esiste = true;

                    if (Domanda.length > 0) {
                        value = Domanda[0].value;
                        esiste = false;
                        var elenco = valoriAccettati.split(",");

                        var len = elenco.length;
                        for (var i=0;i<len;i++) {
                            if (elenco[i] === value) esiste = true;
                        }
                    } */

                    //return valoriAccettati.includes(value);
                    return esiste;
                });

                //console.log("RisposteValide:",risposteValide);

                return risposteValide;
            },
            getDomandaType() {
                return this.domanda.type+"";
            },
            isNotValid() {
                this.resetErrorMessage();

                //let statoField = true;

                // controlla se è stato cliccato almeno una volta
                if (!this.selectedField) return false;
                if (commonLib.isEmpty(this.domanda.validation)) return false;

                // splitta tutti i comandi di verifica
                let attributi = this.domanda.validation.split("|");
                let {Messaggio,esito} = validation.verificaRegole(attributi,this.domanda.value);

                this.setErrorMessage(Messaggio);
                this.$emit('error-checked',esito);

                return esito;
            },
        },
        methods: {
            async onInputSelectFilter(input_value, update, abort) {
                await this.$emit('qq-domanda-inputSelect-filter', {
                    input_value,
                    update,
                    abort
                });
            },
            onFilterDataCharge(val) {
                this.$emit("qq-domanda-filter",val);
            },
            onBlur() {
                this.attivaValidazione();
                this.$emit("domanda-blur");
            },
            resetErrorMessage() {
                this.errorMessage = "";
            },
            setErrorMessage(Messaggio) {
                this.errorMessage = Messaggio;
            },
            attivaValidazione() {
                this.selectedField = true;
            },
            btnClickEvent(label) {
                this.$emit("on-btn-clicked",label);
            }
        }
    }

</script>

<style scoped>
    .q-radio--dark .q-radio__inner--truthy {
        color: #fff;
    }

</style>
