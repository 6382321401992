<template>
    <div v-if="is_mounted">
         <div align="center">
            <span class="text-h4">Completa la richiesta di emissione</span>
        </div>
        <br><br>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="is_loading"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="q-pa-md justify-center row">

            <div class="col-12 col-md-5">
                <div v-for="(Domanda, index) in domande"
                                v-bind:key="index"
                                v-bind="Domanda"
                                class="row"
                                @input="resultDomande()">
                    <div class="col-md-3"></div>
                    <div class="col-12 col-md-6">
                        <QQDomanda
                            :questionario_completo="domande"
                            :domanda="Domanda"
                            @error-checked="onErrorChecked(index,$event)"

                            :visible="isDomandaVisible(Domanda)" />
                    </div>
                    <div class="col-md-3"></div>

                </div>
            </div>

            <div class="col-12 col-md-5">

                <q-list>
                    <q-item v-for="(proposta, index) in proposte_attive" v-bind:key="index" v-bind="proposta">
                        <q-item-section>
                            {{ proposta.tariffa }}
                            <FieldSet>
                                <legend>Frazionamento {{proposta.label}}</legend>
                                <q-option-group
                                    :ref="'frazionamento'+index"
                                    :name="'frazionamento'+index"
                                    :options="lista_frazionamento(proposta)"
                                    v-model="frazionamento[proposta.tariffa]"
                                />
                            </FieldSet>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>
        </div>

        <br><hr>
        <div class="row" align="center">
            <div class="col-12 col-md-6" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>
            <div class="col-12 col-md-6" align="center">
                <QQButton label="AVANTI" color="blue-grey" icon="mdi-arrow-right-bold" size="md" :disabled="!pulsante_avanti_abilitato"
                    @click.native="onAvantiClicked"
                />
            </div>
        </div>
        <br><br><br><br><br>

        <q-dialog v-model="persistent" persistent transition-show="scale" transition-hide="scale" >
            <q-card class="bg-teal text-white" style="width: 500px">
                <q-card-section>
                <div class="text-h6">ATTENZIONE:</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    {{ DlgMessage }}
                </q-card-section>

                <q-card-actions align="right" class="bg-white text-teal">
                <q-btn flat label="OK" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>

    </div>
</template>

<script>
    import commonLib from "@/libs/commonLib";
    import QQDomanda from "@/components/QQDomanda.vue";
    import QQButton from "@/components/QQButton.vue";
    import validation from "@/libs/validation";
    import api from "@/libs/api";
    import { mapState } from "vuex";

    export default {
        name: "ImpostaDate",
        components: {
            QQDomanda,
            QQButton
        },
        data() {
            return {
                is_mounted: false,
                errorMessage: "",
                selectedField: false,
                have_errors: [],
                persistent: false,
                DlgMessage: "",
                is_loading: false,
                pulsante_avanti_abilitato: true,
                options: [
                    'Annuale', 'Semestrale'
                ],
                frazionamento: {},
                domande: [
                    {
                        "area": "",
                        "name": "decorrenza",
                        "type": "date",
                        "label": "Data di decorrenza",
                        "value": "",
                        "options": [],
                        "contextt": "",
                        "is_visible": true,
                        "validation": "required|Date",
                        "is_disabled": false,
                        "placeholder": "",
                        "label_append": "",
                        "is_visible_if": "",
                        "label_prepend": ""
                    }
                /*    {
                        "area": "",
                        "name": "frazionamento",
                        "type": "select",
                        "label": "Frazionamento del premio",
                        "value": "",
                        "options": [
                            {
                                "label": "Annuale",
                                "value": "annuale",
                                "is_visible_if": ""
                            }
                        ],
                        "contextt": "",
                        "is_visible": true,
                        "validation": "required",
                        "is_disabled": false,
                        "placeholder": "",
                        "label_append": "",
                        "is_visible_if": "",
                        "label_prepend": ""
                    } */
                ]
            }
        },
        computed: {
            isNotValid() {
                this.resetErrorMessage();

                // controlla se è stato cliccato almeno una volta
                if (!this.selectedField) return false;
                if (commonLib.isEmpty(this.domanda.validation)) return false;

                // splitta tutti i comandi di verifica
                let attributi = this.domanda.validation.split("|");
                let {Messaggio,esito} = validation.verificaRegole(attributi,this.domanda.value);

                this.setErrorMessage(Messaggio);
                this.$emit('error-checked',esito);

                return esito;
            },
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                elenco_documenti: state => state.elenco_documenti,
                // is_loading: state => state.formPreventivo.is_loading
            }),
            prodotti_attivi() {
                var prodotti = this.formPreventivo.preventivo.prodotti;
                return prodotti.filter(prodotto => {
                    return prodotto.is_checked;
                });
            },
            proposte_attive() {
                var prodotti = this.prodotti_attivi;
                var proposte = [];
                for (var i=0;i<prodotti.length;i++) {
                    var prodotto = prodotti[i];
                    var elenco_proposte = prodotto.proposte.filter(proposta => {
                        return proposta.is_checked;
                    });
                    if (!commonLib.isEmpty(elenco_proposte))
                        proposte.push(elenco_proposte[0]);
                }

                //console.log("proposte:",proposte);

                return proposte;
            }
        },
        methods: {
            resultDomande() {
                //this.$emit('input',this.domande);

                return this.domande;
            },
            onErrorChecked(index,isNotValidForm) {
                this.have_errors[index] = isNotValidForm;
                this.$emit('error-checked',this.have_errors);
            },
            resetErrorMessage() {
                this.errorMessage = "";
            },
            setErrorMessage(Messaggio) {
                this.errorMessage = Messaggio;
            },
            attivaValidazione() {
                this.selectedField = true;
            },
            isDomandaVisible(SingolaDomanda) {

                if (!SingolaDomanda.is_visible) return false;

                if (!commonLib.isEmpty(SingolaDomanda.is_visible_if)) {
                    // Recupera i nome del campo di riferimento
                    let NomeCampo = SingolaDomanda.is_visible_if.split(":")[0];
                    let ValoreCampo = SingolaDomanda.is_visible_if.split(":")[1];
                    // Cerca il campo con l'attributo NAME uguale a quello cercato

                    let Campo = this.domande.filter(domanda => {
                        return domanda.name == NomeCampo;
                    });
                    // Cerca il valore impostato sul campo appena trovato
                    let value = ""
                    if (Campo.length > 0) {
                        value = Campo[0].value;
                    }

                    if (value == "") return false;

                    return ValoreCampo.includes(value);
                }
                return true;
                //return SingolaDomanda.is_visible;
            },
            attivaPulsanti() {
                this.pulsante_avanti_abilitato = true;
            },
            disattivaPulsanti() {
                this.pulsante_avanti_abilitato = false;
                 setTimeout(()=>{
                    this.attivaPulsanti()
                }, 30000);
            },
            onIndietroClicked() {
                this.$router.push({name : "Preventivi.GestioneDocumenti"});
            },
            async onAvantiClicked() {
                this.disattivaPulsanti();
                this.is_loading = true;

                let statoControllo = await commonLib.controllaSeQuestionarioCompilato(this.domande);

                console.log("statoControllo:",statoControllo,this.frazionamento);

                if (!statoControllo) {

                    this.DlgMessage = "Il questionario non è stato compilato correttamente. Verifica prima di continuare";
                    this.persistent = true;
                    this.have_errors = true;
                    this.attivaPulsanti();
                    this.is_loading = false;
                    return;
                }

                this.formPreventivo.preventivo.decorrenza = this.domande[0].value;

                var elenco_proposte = this.proposte_attive;
                for (var i=0;i<elenco_proposte.length;i++) {
                    var proposta = elenco_proposte[i];
                    var tariffa = proposta.tariffa;

                    proposta.frazionamento = this.frazionamento[tariffa];

                    console.log("tariffa:",proposta.frazionamento);

                    var decorrenza = this.formPreventivo.preventivo.decorrenza;
                    var anno = parseInt(decorrenza.split("/")[2]);
                    var mese = parseInt(decorrenza.split("/")[1]);
                    var giorno = decorrenza.split("/")[0];

                    switch (proposta.frazionamento) {
                        case "annuale":
                           proposta.scadenza = giorno.padStart(2,'0')+"/"+(mese+"").padStart(2,'0')+"/"+(anno+1);
                        break;

                        case "semestrale":
                            mese += 6;
                            if (mese > 12) {
                                anno++;
                                mese = 12 - mese;
                            }
                            proposta.scadenza = giorno.padStart(2,'0')+"/"+(mese+"").padStart(2,'0')+"/"+(anno+"");
                        break;

                        case "unico":
                            var durata_anni = proposta.durata_anni;
                            anno += durata_anni;
                            proposta.scadenza = giorno.padStart(2,'0')+"/"+(mese+"").padStart(2,'0')+"/"+(anno+"");
                        break;
                    }

                    this.is_loading = false;
                }

                // TODO - Recupera i dati del frazionamento per ciascuna pratica
                /*for (var i=0; i < this.frazionamento.length; i++) {
                    var tariffa = this.frazionamento[i].tariffa;

                    var proposta = this.proposte_attive.find(prop => {
                        return prop.tariffa === tariffa;
                    });

                    console.log("proposta:",proposta,tariffa);

                    proposta.frazionamento = this.frazionamento[i].value;
                    var decorrenza = this.formPreventivo.preventivo.decorrenza;
                    var anno = parseInt(decorrenza.split("/")[2]);
                    var mese = parseInt(decorrenza.split("/")[1]);
                    var giorno = decorrenza.split("/")[0];

                    switch (proposta.frazionamento) {
                        case "annuale":
                           proposta.scadenza = giorno.padStart(2,'0')+"/"+(mese+"").padStart(2,'0')+"/"+(anno+1);
                        break;

                        case "semestrale":
                            mese += 6;
                            if (mese > 12) {
                                anno++;
                                mese = 12 - mese;
                            }
                            proposta.scadenza = giorno.padStart(2,'0')+"/"+(mese+"").padStart(2,'0')+"/"+(anno+"");
                        break;

                        case "unico":
                            var durata_anni = proposta.durata_anni;
                            anno += durata_anni;
                            proposta.scadenza = giorno.padStart(2,'0')+"/"+(mese+"").padStart(2,'0')+"/"+(anno+"");
                        break;
                    }
                }*/

                var mioFormPreventivo = {
                    "formPreventivo": this.formPreventivo
                }

                let url = process.env.VUE_APP_API_URL + "pratiche/registraPratica";
                let nuovoFormPreventivo = await api.postJSonToController(url,mioFormPreventivo);

                this.formPreventivo.preventivo.id = nuovoFormPreventivo.data.formPreventivo.preventivo.id;
                this.formPreventivo.preventivo.analisi_necessita =
                    nuovoFormPreventivo.data.formPreventivo.preventivo.analisi_necessita;
                this.formPreventivo.preventivo.analisi_rischio =
                    nuovoFormPreventivo.data.formPreventivo.preventivo.analisi_rischio;
                this.formPreventivo.dati_cliente = nuovoFormPreventivo.data.formPreventivo.dati_cliente;

                this.formPreventivo.intermediario_capo_gruppo = nuovoFormPreventivo.data.formPreventivo.intermediario_capo_gruppo;

                this.$router.push({name : "Preventivi.FineAcquisto"});
            },
            lista_frazionamento(proposta) {
                var is_semestrale = proposta.is_semestrale;
                var is_premio_unico = proposta.is_premio_unico;
                var select_option = [
                    { label: "Annuale", value: "annuale", tariffa: proposta.tariffa }
                ];

                if (commonLib.isEmpty(is_semestrale)) is_semestrale = false;
                if (commonLib.isEmpty(is_premio_unico)) is_premio_unico = false;

                if (is_premio_unico) {
                    return [
                        { label: "Premio Unico", value: "premio_unico", tariffa: proposta.tariffa }
                    ];
                }

                if (is_semestrale) {
                    return [
                        { label: "Annuale", value: "annuale", tariffa: proposta.tariffa },
                        { label: "Semestrale", value: "semestrale", tariffa: proposta.tariffa }
                    ];
                }

                return select_option;
            }
        },

        mounted() {
            console.log("MOUNTED");
        /*    var elenco_proposte = this.proposte_attive;
            for (var i=0;i<elenco_proposte.length;i++) {
                //var proposta = elenco_proposte[i];

                this.frazionamento[i] = "";
            }
            console.log("proposte attive:",this.proposte_attive);
            console.log("frazionamento:",this.frazionamento); */

            this.is_mounted = true;
        }
    }
</script>

<style scoped>
    td.domanda {
        text-align: right;
        font-style: italic;
        font-weight: bold;
    }
    td.risposta {
        border-bottom: 1px SOLID #c0c0c0;
        padding: 6px;
        width: 60%;
        vertical-align: bottom;
    }
    .table {
        width: 100%;
    }
</style>
